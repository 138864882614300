import React from 'react';
import { withTheme } from 'styled-components';

export default withTheme(({ theme }) => (
  <svg
    height="67px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 67 67"
    width="67px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M28.765,50.32h6.744V33.998h4.499l0.596-5.624h-5.095  l0.007-2.816c0-1.466,0.14-2.253,2.244-2.253h2.812V17.68h-4.5c-5.405,0-7.307,2.729-7.307,7.317v3.377h-3.369v5.625h3.369V50.32z   M33,64C16.432,64,3,50.568,3,34C3,17.431,16.432,4,33,4s30,13.431,30,30C63,50.568,49.568,64,33,64z"
      style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
      fill={theme.dark}
    />
  </svg>
));
